import { render, staticRenderFns } from "./Gallery.vue?vue&type=template&id=d606f9bc&scoped=true"
import script from "./Gallery.vue?vue&type=script&lang=ts"
export * from "./Gallery.vue?vue&type=script&lang=ts"
import style0 from "./Gallery.vue?vue&type=style&index=0&id=d606f9bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d606f9bc",
  null
  
)

export default component.exports